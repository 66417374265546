import React from "react"
import TabProgetti from "../components/TabProgetti/TabProgetti"
import { graphql } from "gatsby"
import ProjectList from "../components/ProjectList/ProjectList"
import Heading from "../components/Heading/Heading"
import styles from "./pages.module.scss"
import TransitionBehavior from "../components/TransitionBehavior/TransitionBehavior"
import Header from "../components/Header/Header"
import SEO from "../components/seo"
import Footer from "../components/Footer/Footer"
import HeaderImg from "../components/HeaderImg/HeaderImg"

export default function portfolio({ data, transitionStatus }) {
  let transitionClass = "transitionContents " + transitionStatus
  const projectArray = data.portfolio.edges.filter(({ node: portfolio }) => {
    return portfolio.acf.featured
  })
  const meta = data.portfolioArchive.edges[0].node.yoast

  return (
    <TransitionBehavior>
      <div className={transitionClass}>
        <Header />
        <SEO meta={meta} />
        <HeaderImg
          tipoheader={"testataStandardPullUp"}
          img={null}
          coloreBackground={"#fff0f0"}
        >
          <Heading
            title={"Portfolio"}
            subtitle={"Rapide innovazioni di valore"}
            ish1={true}
          ></Heading>
        </HeaderImg>
        <div className={styles.containerMedium}>
          <ProjectList
            projectArray={projectArray}
            immagine={true}
            testimonial={true}
          />
          <TabProgetti data={data} />
        </div>
        <Footer />
      </div>
    </TransitionBehavior>
  )
}

export const query = graphql`
  {
    portfolioArchive: allWordpressPage(filter: { slug: { eq: "portfolio" } }) {
      edges {
        node {
          yoast {
            canonical
            focuskw
            linkdex
            meta_robots_adv
            meta_robots_nofollow
            meta_robots_noindex
            metadesc
            metakeywords
            opengraph_description
            opengraph_image
            opengraph_title
            redirect
            title
            twitter_description
            twitter_image
            twitter_title
          }
        }
      }
    }
    portfolio: allWordpressWpProgetto {
      edges {
        node {
          id
          slug
          acf {
            titolo
            sottotitolo
            header {
              source_url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1980, quality: 10) {
                    src
                    srcSet
                    aspectRatio
                    sizes
                  }
                }
              }
            }
            descrizione
            link_correlati {
              post_title
            }
            layout_progetto {
              ... on WordPressAcf_testimonial {
                testimonial {
                  autore
                  autore_sottotitolo
                  quote
                  home
                  immagine {
                    source_url
                    localFile {
                      childImageSharp {
                        fluid {
                          src
                          srcSet
                          aspectRatio
                          sizes
                        }
                      }
                    }
                  }
                  __typename
                }
              }
            }
            featured
          }
        }
      }
    }
  }
`
